import React from 'react';
import privacy from '../public/privacy_policy.pdf';
import unika_checkup_terms from '../public/unika_checkup_terms.pdf';
import { Settings } from './useSettings';

export const useRoutes = () => {
  const {
    settings: { language },
  } = Settings.useContainer();
  const root = React.useMemo(() => `/${language}`, [language]);
  const mina = React.useMemo(() => `${root}/mina`, [root]);
  const quiz = React.useMemo(() => `${root}/quiz`, [root]);
  const insurance = React.useMemo(() => `${root}/insurance`, [root]);
  const secureInsurance = React.useMemo(() => `${root}/mina/insurance`, [root]);
  const checkup = React.useMemo(() => `${root}/checkup/order`, [root]);

  return {
    root,
    mina,
    insurance,
    secureInsurance,
    checkup,
    privacy,
    quiz,
    unika_checkup_terms,
  };
};
