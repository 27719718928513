import Badge from '@material-ui/core/Badge';
import { Alert } from '@material-ui/lab';
import { bankName } from 'clearingnummer';
import { TextField } from 'final-form-material-ui';
import Kontonummer from 'kontonummer';
import React, { useState } from 'react';
import { useField } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Account, PaymentPayload } from '../../swagger';
import { PaymentMethodFetchAccounts } from './PaymentMethodFetchAccounts';
import { PaymentMethodField } from './PaymentMethodField';

export type BankProps = {
  bank?: string;
  isBroker?: boolean;
  change: (name: keyof PaymentPayload, value?: any) => void;
  payerType: Account.TypeEnum;
};

export const PaymentMethodBankAccount: React.FC<BankProps> = React.memo(
  (props) => {
    const { bank, change, isBroker, payerType } = props;
    const [showError, setShowError] = useState(false);

    const {
      input: { value: clearingValue },
      meta: { modified: clearingTouched },
    } = useField('clearing');
    const {
      input: { value: accountValue },
      meta: { modified: accountTouched },
    } = useField('account');
    const intl = useIntl();

    React.useEffect(() => {
      if (!clearingTouched || !accountTouched) {
        setShowError(false);
        return;
      }
      try {
        Kontonummer.parse(clearingValue, accountValue);
        setShowError(false);
      } catch (e) {
        const timer = setTimeout(() => {
          setShowError(true);
        }, 1000);

        return () => clearTimeout(timer);
      }
    }, [clearingValue, accountValue, clearingTouched, accountTouched]);

    const validateClearing = React.useCallback(
      (clearing = '') => {
        const length = `${clearing}`.trim().length;

        if (!length) {
          change('bank', '');
        }

        if (length < 3) {
          return intl.messages.incorrect_field;
        }

        if (!bank) {
          const newBank = bankName(clearing);
          change('bank', newBank || '');
        }
      },
      [bank],
    );
    const validateAccountNumber = React.useCallback(
      (accountNumber = '') =>
        `${accountNumber}`.trim().length > 3
          ? undefined
          : intl.messages.incorrect_field,
      [],
    );
    const validateBank = React.useCallback(
      (value = '') =>
        `${value}`.trim().length > 1
          ? undefined
          : intl.messages.incorrect_field,
      [],
    );

    return (
      <>
        {!isBroker && (
          <PaymentMethodFetchAccounts payerType={payerType} change={change} />
        )}
        <Badge invisible={!showError} color="error" variant="dot">
          <PaymentMethodField
            type="tel"
            name="clearing"
            component={TextField}
            validate={validateClearing}
            label={intl.messages.clearing_number}
            inputProps={
              showError
                ? { style: { borderColor: '1px solid red' } }
                : undefined
            }
          />
        </Badge>
        <Badge invisible={!showError} color="error" variant="dot">
          <PaymentMethodField
            type="tel"
            name="account"
            component={TextField}
            validate={validateAccountNumber}
            label={intl.messages.bank_account_number}
          />
        </Badge>
        <PaymentMethodField
          type="text"
          name="bank"
          component={TextField}
          validate={validateBank}
          label={intl.messages.payment_provider}
        />

        {showError && (
          <Alert severity="warning" style={{ marginBottom: '2rem' }}>
            <FormattedMessage id="payment_method_unverified" />
          </Alert>
        )}
      </>
    );
  },
);
