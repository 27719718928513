import { TextField } from 'final-form-material-ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { Account } from '../../swagger';
import { validateEmail } from '../../utils/validateEmail';
import { PaymentMethodField } from './PaymentMethodField';

type Props = {
  isBroker?: boolean;
  payerType: Account.TypeEnum;
};

export const PaymentMethodAccount: React.FC<Props> = ({
  isBroker,
  payerType,
}) => {
  const intl = useIntl();
  const validateE = React.useCallback(
    (email = '') => {
      if (isBroker && !email) return;
      return validateEmail(email) ? undefined : intl.messages.invalid_email;
    },
    [isBroker],
  );
  const validatePhone = React.useCallback(
    (phone = '') => {
      if (isBroker && !phone) return;
      return `${phone}`.trim().length > 6
        ? undefined
        : intl.messages.invalid_phone;
    },
    [isBroker],
  );

  return (
    <>
      <PaymentMethodField
        autoFocus
        type="tel"
        name="personnummer"
        component={TextField}
        label={
          payerType === Account.TypeEnum.Person
            ? intl.messages.personal_number
            : intl.messages.company_number
        }
      />
      <PaymentMethodField
        type="email"
        name="email"
        component={TextField}
        validate={validateE}
        label={
          payerType === Account.TypeEnum.Person
            ? intl.messages.email_for_receipts
            : intl.messages.faktura_email
        }
      />
      <PaymentMethodField
        type="tel"
        name="phone"
        component={TextField}
        validate={validatePhone}
        label={intl.messages.phone}
      />
    </>
  );
};
