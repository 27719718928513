import { DateTime } from 'luxon';
import React from 'react';
import { useRouteMatch } from 'react-router';
import useSessionStorage from 'react-use/lib/useSessionStorage';
import { Order, PaymentPayload, Person, Quiz } from '../swagger';
import { api } from '../utils/api';
import { Account } from './useAccount';
import { useBrokers } from './useBrokers';
import { usePricings } from './usePricings';
import { useQuiz } from './useQuiz';

const initialDate = DateTime.utc().plus({ day: 1 }).toISODate();

interface useCheckupProps {
  sessionStorageKey: string;
  order?: Order;
  defaultPaymentPeriod?: number;
  skipQuiz?: boolean;
}

export const useProductOrder = ({
  sessionStorageKey,
  order,
  defaultPaymentPeriod = 1,
  skipQuiz = false,
}: useCheckupProps) => {
  const { loading: accountLoading, brokers, admin } = Account.useContainer();
  const {
    params: { offerId: newOfferId },
  } = useRouteMatch<{ offerId?: string }>();
  const { saveOrCreateQuiz } = useQuiz();

  const [ready, setReady] = React.useState(false);
  const [selectedPricing, setSelectedPricing] = React.useState<string>();
  const [selectedBroker, setSelectedBroker] = React.useState<string>();
  const [lab, setLab] = React.useState<string>('');

  const [persons, setPersons] = useSessionStorage<Person[]>(
    `${sessionStorageKey}/persons`,
    [],
  );
  const [pendingMethod, setPendingMethod] = useSessionStorage<
    PaymentPayload | undefined
  >(`${sessionStorageKey}/pendingMethod`, undefined);
  const [paymentPeriod, setPaymentPeriod] = useSessionStorage<number>(
    `${sessionStorageKey}/paymentPeriod`,
    order?.paymentPeriod || defaultPaymentPeriod,
  );
  const [startDate, setStartDate] = useSessionStorage<string>(
    `${sessionStorageKey}/startDate`,
    initialDate,
  );
  const [consent, setConsent] = useSessionStorage<boolean>(
    `${sessionStorageKey}/consent`,
    false,
  );
  const [offerId, setOfferId] = useSessionStorage<string | undefined>(
    `${sessionStorageKey}/offerId`,
    newOfferId,
  );

  const isBroker = (!!brokers.length || admin) && !offerId;

  // select first available pricing
  const [pricings] = usePricings(isBroker);
  const firstPricing = pricings?.[0]?.id;
  React.useEffect(() => {
    if (!offerId && firstPricing && !selectedPricing) {
      setSelectedPricing(firstPricing);
    }
  }, [offerId, firstPricing, selectedPricing]);

  // select first available broker
  const availableBrokers = useBrokers(isBroker);
  const firstBroker = !!brokers.length && availableBrokers?.[0]?.id;
  React.useEffect(() => {
    if (!offerId && firstBroker && !selectedBroker) {
      setSelectedBroker(firstBroker);
    }
  }, [offerId, firstBroker, selectedBroker]);

  React.useEffect(() => {
    if (newOfferId && newOfferId !== offerId) {
      setOfferId(newOfferId);
    }
  }, [newOfferId, offerId]);

  React.useEffect(() => {
    if (offerId && offerId.length < 36) {
      setOfferId(undefined);
    }
  }, [offerId]);

  React.useEffect(() => {
    if (accountLoading || ready) {
      return;
    }

    (async () => {
      if (offerId) {
        const offer = await api.orderControllerGetOffer(offerId);

        setPersons(offer.persons);
        setPaymentPeriod(offer.paymentPeriod ?? defaultPaymentPeriod);
        setConsent(offer.consent || false);
        setStartDate(offer.startDate || initialDate);
        setPendingMethod(offer.paymentMethod);
        setSelectedPricing(offer.pricingId);
        setReady(true);
        return;
      }

      if (!isBroker && persons.length) {
        setReady(true);
        return;
      }

      setPersons([
        {
          type: Person.TypeEnum.Main,
          quiz: skipQuiz ? undefined : await saveOrCreateQuiz({} as Quiz),
        },
      ]);
      setPaymentPeriod(defaultPaymentPeriod);
      setConsent(false);
      setStartDate(initialDate);
      setPendingMethod(undefined);
      setSelectedPricing(undefined);
      setSelectedBroker(undefined);
      setReady(true);
      setOfferId(undefined);
    })();
  }, [persons.length, ready, accountLoading, isBroker, offerId]);

  return {
    selectedPricing,
    selectedBroker,
    pendingMethod,
    paymentPeriod,
    startDate,
    consent,
    offerId,
    persons,
    ready,
    isBroker,
    pricings,
    availableBrokers,
    lab,
    // methods
    setSelectedPricing,
    setSelectedBroker,
    setPersons,
    setPendingMethod,
    setConsent,
    setPaymentPeriod,
    setStartDate,
    setReady,
    setLab,
  };
};
