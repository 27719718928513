import Bugsnag from '@bugsnag/js';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert/Alert';
import * as React from 'react';
import { Form, FormRenderProps, useField } from 'react-final-form';
import { useIntl } from 'react-intl';
import { Account } from '../../hooks/useAccount';
import { PaymentMethods } from '../../hooks/usePaymentMethods';
import {
  Account as AccountType,
  ConnectPaymentResponse,
  InlineResponse400,
  PaymentPayload,
  Person,
} from '../../swagger';
import { api } from '../../utils/api';
import { clearPersonalNumber } from '../../utils/clearPersonalNumber';
import { Markdown } from '../Markdown';
import { PaymentAutoManual } from './PaymentAutoManual';
import { PaymentMethodAccount } from './PaymentMethodAccount';
import {
  BankProps,
  PaymentMethodBankAccount,
} from './PaymentMethodBankAccount';
import { PaymentMethodCompany } from './PaymentMethodCompany';
import { SubmitButton } from './SubmitButton';

type Props = {
  value?: PaymentPayload;
  onComplete: (response?: ConnectPaymentResponse) => void;
  onSubmit?: (payload: PaymentPayload) => void;
  isBroker?: boolean;
  mainPerson?: Person;
};

type FormBodyProps = BankProps & {
  handleSubmit: () => void;
  submitting: boolean;
  invalid: boolean;
  isBroker?: boolean;
  payerType: AccountType.TypeEnum;
  setPayerType: (newType?: AccountType.TypeEnum) => void;
};

const FormBody: React.FC<FormBodyProps> = (props) => {
  const {
    bank,
    handleSubmit,
    change,
    submitting,
    invalid,
    isBroker,
    payerType,
  } = props;
  const { error } = PaymentMethods.useContainer();
  const {
    input: { value: isManual },
  } = useField<boolean | null>('manual', {
    defaultValue: null,
    allowNull: true,
  });
  const {
    input: { value: personnummer },
  } = useField<string | undefined>('personnummer', {
    defaultValue: undefined,
    allowNull: true,
  });

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <PaymentMethodAccount payerType={payerType} isBroker={isBroker} />
      {payerType === AccountType.TypeEnum.Company && (
        <PaymentMethodCompany personnummer={personnummer} isBroker={isBroker} />
      )}
      <PaymentAutoManual isBroker={isBroker} />
      {isManual === false && (
        <PaymentMethodBankAccount
          bank={bank}
          change={change}
          payerType={payerType}
          isBroker={isBroker}
        />
      )}
      {error && (
        <Alert severity="error">
          <Box>
            <Markdown children="payment_method_failure" variant={false} />{' '}
            {error}
          </Box>
        </Alert>
      )}
      <Box pb={3}>
        <SubmitButton
          type="submit"
          disabled={invalid}
          loading={submitting}
          label={
            isBroker
              ? 'add_payer'
              : isManual === false
              ? 'connect_bank'
              : 'add_payment'
          }
        />
      </Box>
    </form>
  );
};

const isResponse = (e: any): e is Response => 'status' in e;

export const PaymentMethodForm: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { value, onComplete, onSubmit, isBroker } = props;
  const { setError } = PaymentMethods.useContainer();
  const { account, refreshAccount } = Account.useContainer();
  const [payerType, setPayerType] = React.useState<
    AccountType.TypeEnum | undefined
  >();
  const addPaymentMethod = React.useCallback(
    async (payload: PaymentPayload) => {
      if (onSubmit) {
        await onSubmit(payload);
        return onComplete();
      }

      try {
        const response = await api.paymentControllerConnectPaymentMethod(
          payload,
        );

        if (response.id) {
          await refreshAccount();
          onComplete(response);
        } else {
          throw new Error('Cannot connect payment method');
        }
      } catch (e) {
        console.error(e);
        if (isResponse(e)) {
          const error: InlineResponse400 = await e.json();
          Bugsnag.notify(error.message);
          setError(error.message);
        } else {
          Bugsnag.notify(e);
          setError(e.message);
        }
      }
    },
    [onSubmit],
  );
  const isPerson = payerType === AccountType.TypeEnum.Person;
  const prefillPerson =
    (!isBroker && isPerson && (account?.sensitive || props.mainPerson)) ||
    undefined;
  const initialValues: PaymentPayload = value || {
    email: prefillPerson?.email || '',
    phone: prefillPerson?.phone || '',
    personnummer: prefillPerson?.personnummer || '',
    manual: isBroker ? null : false,
  };

  const updatePayerType = (newType: AccountType.TypeEnum) => {
    if (newType !== payerType) {
      setPayerType(newType);
    }
  };

  const renderForm = React.useCallback(
    ({
      handleSubmit,
      submitting,
      invalid,
      form: { change },
      values: { bank, personnummer },
    }: FormRenderProps<PaymentPayload>) => {
      const pno = clearPersonalNumber(personnummer);

      if (!payerType) {
        if (pno) {
          setPayerType(
            pno.length === 10
              ? AccountType.TypeEnum.Company
              : AccountType.TypeEnum.Person,
          );
        }
        return null;
      }

      return (
        <FormBody
          payerType={payerType}
          setPayerType={setPayerType}
          isBroker={isBroker}
          handleSubmit={handleSubmit}
          submitting={submitting}
          invalid={invalid}
          change={change}
          bank={bank}
        />
      );
    },
    [isBroker, payerType, setPayerType],
  );

  return (
    <>
      <Markdown children="payment.intro" variant="body2" color="textPrimary" />
      <Box my={4} display="flex" justifyContent="center">
        <ButtonGroup color="primary">
          <SubmitButton
            label="person"
            size="small"
            type="button"
            variant={
              payerType === AccountType.TypeEnum.Person
                ? 'contained'
                : undefined
            }
            onClick={() => updatePayerType(AccountType.TypeEnum.Person)}
          />
          <SubmitButton
            label="company"
            size="small"
            type="button"
            variant={
              payerType === AccountType.TypeEnum.Company
                ? 'contained'
                : undefined
            }
            onClick={() => updatePayerType(AccountType.TypeEnum.Company)}
          />
        </ButtonGroup>
      </Box>
      <Form
        validate={({ personnummer }) => {
          const pno = clearPersonalNumber(personnummer);
          console.log({ pno, payerType });
          return {
            personnummer:
              !pno || (isPerson && pno.length !== 12)
                ? isPerson
                  ? intl.messages.invalid_personal_number
                  : intl.messages.invalid_org_number
                : undefined,
          };
        }}
        initialValues={initialValues}
        onSubmit={addPaymentMethod}
        render={renderForm}
      />
    </>
  );
};
