import { TextField } from 'final-form-material-ui';
import React from 'react';
import { useForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import {
  PaymentPayload,
  Sensitive,
  SensitiveLookupPayload,
} from '../../swagger';
import { api } from '../../utils/api';
import { clearPersonalNumber } from '../../utils/clearPersonalNumber';
import { PaymentMethodField } from './PaymentMethodField';
import TypeEnum = SensitiveLookupPayload.TypeEnum;

type Props = {
  personnummer?: string;
  isBroker?: boolean;
};

export const PaymentMethodCompany: React.FC<Props> = React.memo(
  ({ isBroker, personnummer: pno }) => {
    const intl = useIntl();
    const required = React.useCallback(
      (v = '') => {
        if (isBroker && !v) return;
        if (!v) {
          return intl.messages.incorrect_field;
        }
      },
      [isBroker],
    );
    const personnummer = clearPersonalNumber(pno);
    const isCompany = personnummer && personnummer.length === 10;

    const { getState, batch, change } = useForm<PaymentPayload>();

    React.useEffect(() => {
      if (!isCompany || !personnummer) {
        return;
      }

      let stillRelevant = true;

      api
        .sensitiveControllerLookup({
          nummer: personnummer,
          type: TypeEnum.Company,
        })
        .then((result) => {
          if (!stillRelevant) return;

          const values = getState().values;
          const keysToApply: Partial<
            Record<keyof Sensitive, keyof PaymentPayload>
          > = {
            fullName: 'companyName',
            streetAddress: 'streetAddress',
            locality: 'locality',
            postalCode: 'postalCode',
          };

          batch(() => {
            Object.entries(keysToApply).forEach(
              ([sensitiveKey, payloadKey]) => {
                const key = sensitiveKey as keyof Sensitive;
                if (!values[payloadKey] && result[key]) {
                  change(payloadKey, result[key]);
                }
              },
            );
          });
        })
        .catch((e) => console.error(e));

      return () => {
        stillRelevant = false;
      };
    }, [personnummer, isCompany]);

    return (
      <>
        <PaymentMethodField
          type="text"
          name="companyName"
          component={TextField}
          validate={required}
          label={intl.messages.company_name}
        />
        <PaymentMethodField
          type="text"
          name="streetAddress"
          component={TextField}
          validate={required}
          label={intl.messages.address}
        />
        <PaymentMethodField
          type="text"
          name="postalCode"
          component={TextField}
          validate={required}
          label={intl.messages.postal_code}
        />
        <PaymentMethodField
          type="text"
          name="locality"
          component={TextField}
          validate={required}
          label={intl.messages.locality}
        />
      </>
    );
  },
);
